.sidebarr{
  /*  width:100vw;
    height:100vh; */
    height: 100vh;
    margin:0px;
    padding: 0px;

}
.contactForm{
    min-height: 100vh;
    border : 2px solid black;
    float : left;
    min-width: 75%;
}

.basicDetailForm{
    min-height: 100vh;
    border : 2px solid black;
   
    min-width: 75%;
}

.nomineeForm{
    min-height: 100vh;
    border : 2px solid black; 
    float : left;
    min-width: 75%;
}

.ReportForm{
    min-height: 100vh;
    border : 2px solid black;  
    float : left; 
    min-width: 75%;
}

.AccountDetailForm{
    min-height: 100vh;
    border : 2px solid black; 
    float : left;
    min-width: 75%;
}

.additionalDetailForm{
    min-height: 100vh;
    border : 2px solid black; 
    float : left;
    min-width: 75%;
}

.sidebar-whole{
    float: left;
}

.validateStyle{
  color:red;
  font-weight : bold;
}

.basic-detail , .sidebar-whole{
    float : left;
}

.basic-detail ,.basicDetailForm{
    float :left;
}