*{
    margin : 0;
    padding : 0;
}

.container-Login{
    width:100%;
    height:100vh;
    font-family:sans-serif;
    background: rgb(232 229 211);
    color: rgb(24, 17, 17);
    display:flex;
    align-items:center;
    justify-content: center;
    font-weight: bold !important;
}
.login-panel{
    width: 350px;
    height: 500px;
    box-shadow: 0 0 40px 20px rgba(0, 0, 0, 0.26);
    perspective: 1000px;
}

.inner-box{
    position: relative;
    width: 100%;
    height:100%;
    /*transform: rotateY(-180deg) ;*/
    transform-style: preserve-3d;
}
.card-front , .card-back{
    position: absolute;
    width: 100%;
    height:100%;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(rgb(239 196 83 / 80%),rgb(231 231 245 / 80%));
    padding: 55px;
    box-sizing: border-box;
    backface-visibility: hidden;
}

.card-back{
   transform: rotateY(180deg) !important;

}

.login-panel h2{
    font-weight: normal;
    font-size: 24px;
    text-align:center;
    margin-bottom: 20px;
}

.input-box{
    background: transparent;
    border:1px solid rgb(19, 2, 2);
    margin:6px 0;
    height: 32px;
    border-radius: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    outline: none;
    text-align: center;
    color:rgb(12, 1, 1);

}

::placeholder{
    color:rgb(22, 3, 3);
    font-size: 12px;
}

button{
    width: 100%;
    background:transparent;
    border:1px solid rgb(24, 2, 2);
    margin:35px 0 10px !important;
    height:32px;
    font-size: 12px !important;
    border-radius: 20px !important;
    padding: 0 10px;
    box-sizing: border-box;
    outline: none;
    color:rgb(14, 1, 1);
    cursor:pointer !important;
}

.submit-btn{
    position:relative;

}

submit-btn::after{
    content: '\27a4';
    color:#333;
    line-height: 32px;
    font-size: 17px;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #fff;
    position:absolute;
    right: -1px;
    top:-1px;

}

span{
    font-size: 13px;
    margin-left: 10px;
}

.login-panel .btn-Reg{
    margin-top: 70px;
}

.login-panel a{
    color: #fff;
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 13px;
    margin-top: 8px;
}

.signOut-front, .signOut-back {
    position: absolute;
    width: 200%;
    height:100%;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(rgba(0,0,100,0.8),rgba(0,0,100,0.8));
    padding: 55px;
    box-sizing: border-box;
    backface-visibility: hidden;
}

.signup-heading{
    font-weight: bold;
}

.signUp-Label{
    font-size: 19px;
}

.signup-btn{
    color: #fff !important;
    background-color : green !important;
}

.alert-success {
    color: #2a9843 !important;
    background-color: #ffffff !important;
    border-color: #f1fdf4 !important;
    font-weight: bold !important;
    font-size: 19px;

}

.alert-danger {
    color: #d5303f !important;
    background-color: transparent !important;
    border-color: transparent !important;
    font-weight: bold !important;
    font-size: 19px;
}
.alert-danger p{
    color: #d5303f !important;
    background-color: transparent !important;
    border-color: transparent !important;
    font-weight: bold !important;
    font-size: 12px;
}

.registration-form{
    min-height: 100vh;
    width: 40%;
    border : 2px solid black;
    min-width: 75%;
    margin-left: 175px;
}