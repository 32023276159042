.admin-btn {
    margin: 0.375rem;
    color: inherit;
    text-transform: uppercase;
    word-wrap: break-word;
    /* white-space: normal; */
    /* cursor: pointer; */
    border: 0;
    border-radius: 0.125rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    padding: 0.5rem 0.5rem;
    font-size: .81rem;
}