

.sidebar{
   
    /* min-height: 100vh; */
    min-height: 100%;
    width: 250px;
    background-color: #2F4050;
}

.sidebarList{
    height:auto;
    padding: 0px;
    width: 100%;
}

.sidebarList .row{
    width: 100%;
    height: 60px;
    /*border : 1px solid black;*/
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.sidebarList .row:hover{
    cursor: pointer;
    background-color: #293846;
}


.row #icon{
    flex: 30%;
    display: grid;
    place-items: center;
}

.row #title{
    flex: 70%;
}