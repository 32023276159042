

/*{
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box !important;
} */
.animated-Login{
    
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f8ead5;
}

.sign-animated{
    position:relative;
    padding: 68px 15px !important;
    width:900px;
    height:650px !important;
    background: #fff;
    overflow: hidden;
    box-shadow: 8px 8px 18px #fff;
    border-radius: 10px;
}

.inner-form{
    background-image: linear-gradient(rgb(239 196 83 / 80%),rgba(192, 192, 209, 0.8)) !important;

    position: absolute;
    height: 100%;
    width:100%;
    top:50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
      
}
.signIn-logo{
    margin-left: 4%;
   /* display:inline;*/
}

.signIn-content{
    margin-left: 50%;
    height: 50% !important;
    width: 89% !important;
    padding: 15px !important;
}

.inner-form h3{
    font-weight: 19 !important; 
    font-size: 25px !important;
    color: black !important;
    text-align: center !important;
    padding-top: 35px !important;

}

.input{

    padding: 12px 15px;
    width: 50%;
    left:50%;
    border-radius: 4px;
    margin-top: 28px;
    border:1.5px solid #fff;
    outline: none;

    background: transparent;
    border:1px solid rgb(19, 2, 2);
    margin:6px 0;
    height: 32px;
    padding: 0 10px;
    box-sizing: border-box;
    text-align: center;
    color:rgb(12, 1, 1);

}



.signIn-btn:hover{
    background: rgb(209, 192, 135) !important;
   /* background:#a9a9e4;*/
}

input[type=text], select, textarea {
    width: 100% ;
    padding: 12px;
    border: 1px solid #070606;
    border-radius: 4px !important;
    resize: vertical;
}
.signIn-btn{
    width: 50% !important;
   /* background:transparent !important;*/
    background: #8c8c8d;
    border:1px solid rgb(24, 2, 2) !important;
    margin:20px 0 10px !important;
    height:32px !important;
    font-size: 12px !important;
    border-radius: 4px !important;
    padding: 0 10px !important;
    box-sizing: border-box !important;
    outline: none !important;
    color:rgb(249, 244, 244) !important;
    cursor:pointer !important ;
}

.btn-Reg{
    margin-top: 70px;
}

::placeholder{
    color:rgb(22, 3, 3);
    font-size: 12px;
}

.sign-animated span{
    position: absolute;
    height: 50%;
    width: 50%;
}

.login-paragraph {
    font-size: 15px !important;
    float: left;
    width: 350px !important;
    margin-left: 4%;
    margin-top: 20% !important;
    margin-bottom: 0% !important;
    color: black !important;
    font-weight: bold;
    justify-content: block !important;
}
.login-paragraph h4 {
    font-size: 19px;
    margin-top: 17px;
    color: #684603;
    font-weight: bold;
    color: #6a7f06;
}
.login-paragraph h6 {
    font-size: 15px;
    color: #6a7f06;
    font-weight: 900;
    margin-top: 13px;
    font-weight: bold;
}

p{
    margin-top: 0% !important;
    margin-bottom: 0% !important;
    
}

.sign-paragraph{
    font-size: 11px;
    color: black;
}

.sahulat-heading{
    color: #6a7f06;
    /* margin-left: 52%; */
    margin-top: 15%;
   /* display: inline;*/
    white-space: nowrap;
    float: right;
    position: absolute;   
    right: 130px;

}


.sahulat-logo {
    margin-left: 90px;
}
.signin-logo{
    float : left;
}
input
.signin-heading{
    float : left;
}

.box-user-id{
	background		:url(./img/FSDGE/icon-user.png) no-repeat scroll 7px 7px ;

}

.box-user-pwd{
    background		:url(./img/FSDGE/icon-lock.png) no-repeat scroll 7px 7px ;

}
.SignIn-Heading{
    margin-bottom: 0;
    color: #6a7f06;
}

.input-container {

  }

  .icon {
    padding: 10px;
    background: dodgerblue;
    color: white;
    min-width: 50px;
    text-align: center;
  }

  .login .content .input-icon i {
    border-right: 1px solid #c2cad8;
    margin: 0px;
    padding: 13px 11px;
    height: 43px;
    width: 43px;
}
/*.sign-animated span:nth-child(1){
    background: #ffda85;
    top: 0;
    left: -48%;
    animation: 5s span1 infinite linear;
    animation-delay: 1s;

}

@keyframes span1 {
    0%
    {
        top: -48%;
        left: -48%;
    }
    
    25%
    {
        top: -48%;
        left: 98%;
    }

    50%
    {
        top:98%;
        left:98%;
    }

    75%
    {
        top:98%;
        left: -48%;
    }

    100%
    {
        top:-48%;
        left: -48%;
    }
}

.sign-animated span:nth-child(2){
    background: #05d1f6;
    bottom: 0;
    right: -48%;
    animation: 5s span2 infinite linear;

}

@keyframes span2 {
    0%
    {
        top: -48%;
        left: -48%;
    }
    
    25%
    {
        top: -48%;
        left: 98%;
    }

    50%
    {
        top:98%;
        left:98%;
    }

    75%
    {
        top:98%;
        left: -48%;
    }

    100%
    {
        top:-48%;
        left: -48%;
    }
}

.sign-animated span:nth-child(3){
    background: #20491d;
    right: -48%;
    top: 0;
    animation: 5s span3 infinite linear;

}

@keyframes span3 {
    0%
    {
        top: -48%;
        left: -48%;
    }
    
    25%
    {
        top: -48%;
        left: 98%;
    }

    50%
    {
        top:98%;
        left:98%;
    }

    75%
    {
        top:98%;
        left: -48%;
    }

    100%
    {
        top:-48%;
        left: -48%;
    }
}

.sign-animated span:nth-child(4){
    background: #635206;
    bottom: 0;
    right: -48%;
    animation: 5s span4 infinite linear;
    animation-delay: 1s;
}

@keyframes span4 {
    0%
    {
        top: -48%;
        left: -48%;
    }
    
    25%
    {
        top: -48%;
        left: 98%;
    }

    50%
    {
        top:98%;
        left:98%;
    }

    75%
    {
        top:98%;
        left: -48%;
    }

    100%
    {
        top:-48%;
        left: -48%;
    }
}*/
