

.form-control {
    display: block !important;
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 200 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #141516 !important;
    border-radius: 0.25rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400 !important;
    line-height: 1.5;
    color: #212529 !important;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
}
.form-control {
    display: block;
    z-index: -1;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-label {
    margin-bottom: 0.5rem !important;
    color: #141516 !important;
    font-weight: 410 !important;
  
}

.navbar {
    position: fixed !important;
    top: 0;
    z-index: 1;
    /*width: 100%;*/
    width:-webkit-fill-available;
    font-weight: 500 !important;
   /* background-color: #070f4ae6;*/
     background-color: #f2f3fae6;
    color: #04AA6D !important;
    overflow: hidden;
    text-align: center;
    padding: 0.001rem 0.001rem !important;
    text-decoration: none;
    font-size: 17px;
    justify-content: left  !important;
}
.navbar a:hover{
    background-color: #f2f3fae6;
    color: black;
   
}

/*input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
*/
.navbar a.active {
    background-color: #04AA6D !important;
    color: white !important;
  }
.topnav a:hover {
    background-color: #ddd;
    color: black;
  }

  .logo-navbar{
    margin: 0 !important;
    justify-content: left  !important;
    vertical-align: middle !important;
    border-style: none !important;
    float:right !important;
  }